import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import "../css/Progressbar.css";
import "../css/welcome.css";
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears } from "date-fns";
import { useCookies } from "react-cookie";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getUserData, updateUserData } from "../redux/action/userAction";
import moment from "moment";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";

const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const Youreeligible = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const progressValue = 40;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);

  const setUserDataHandler = (uData) => {
    if (uData.date_of_birth) {
      let dd = moment(uData.date_of_birth).format("DD");
      let mm = months.find(
        (mm) => mm.value === moment(uData.date_of_birth).format("MM")
      );
      let YYYY = moment(uData.date_of_birth).format("YYYY");
      setSelectedDay({ value: dd, label: dd });
      setSelectedMonth(mm);
      setSelectedYear({ value: YYYY, label: YYYY });
    } else {
    }
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/youreeligible",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const calculateAge = () => {
    if (
      selectedDay?.value &&
      typeof selectedMonth.value != "undefined" &&
      selectedYear.value
    ) {
      const selectedDate = new Date(
        Number(selectedYear.value),
        Number(selectedMonth.value) - 1,
        Number(selectedDay.value)
      );
      const age = differenceInYears(new Date(), selectedDate);
      return age;
    }
    return -1;
  };

  const handleSelectButton = () => {
    const age = calculateAge();

    if (age >= 10 && age <= 17) {
      navigate("/userdetails2");
    } else if (age > 17) {
      navigate("/userdetails");
    }
  };

  return (
    <>
      <Header progressValue={progressValue} backRoute={"/dateofbirth"} />
      <TransitionAnimation>
        <div className="container page-main1 mt-0">
          <div className="col-lg-5 col-md-6 col-sm-8 col-9 text-center mx-auto">
            <h3>We’re in-network!</h3>
            <p className="mt-4">
              Everlong’s services are 100% covered by insurance for 95% of
              patients. In rare instances, copays and deductibles may apply, but
              we strive to keep any out-of-pocket costs to a minimum. Our team
              is dedicated to helping you navigate your insurance benefits for
              the best possible coverage.
            </p>
          </div>
          <div className="mt-3">
            <CommonButton onClick={handleSelectButton}>
              Get Started
            </CommonButton>
          </div>
        </div>
      </TransitionAnimation>
    </>
  );
};

export default Youreeligible;
