// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProgressBar.css */
.progress {
    height: 0.5rem !important; 
    background-color: #a1de64 !important;
    /* width !important; Default width */
    margin: auto !important;
  }
  
  .progress-bar {
    background-color: #79ba39 !important;
    border-radius: 8px !important; 
    width: 0; /* Initial width is 0 to show animation when the value changes */
    transition: width 2s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Easing for smooth animation */
  }
  
  @media screen and (max-width: 2560px) {
    .progress {
      width: 40%;
    }
  }
  
  @media screen and (max-width: 1440px) {
    .progress {
      width: 60%;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .progress {
      width: 70%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .progress {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 425px) {
    .progress {
      width: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/Progressbar.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,yBAAyB;IACzB,oCAAoC;IACpC,oCAAoC;IACpC,uBAAuB;EACzB;;EAEA;IACE,oCAAoC;IACpC,6BAA6B;IAC7B,QAAQ,EAAE,gEAAgE;IAC1E,0DAA0D,EAAE,gCAAgC;EAC9F;;EAEA;IACE;MACE,UAAU;IACZ;EACF;;EAEA;IACE;MACE,UAAU;IACZ;EACF;;EAEA;IACE;MACE,UAAU;IACZ;EACF;;EAEA;IACE;MACE,UAAU;IACZ;EACF;;EAEA;IACE;MACE,WAAW;IACb;EACF","sourcesContent":["/* ProgressBar.css */\n.progress {\n    height: 0.5rem !important; \n    background-color: #a1de64 !important;\n    /* width !important; Default width */\n    margin: auto !important;\n  }\n  \n  .progress-bar {\n    background-color: #79ba39 !important;\n    border-radius: 8px !important; \n    width: 0; /* Initial width is 0 to show animation when the value changes */\n    transition: width 2s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Easing for smooth animation */\n  }\n  \n  @media screen and (max-width: 2560px) {\n    .progress {\n      width: 40%;\n    }\n  }\n  \n  @media screen and (max-width: 1440px) {\n    .progress {\n      width: 60%;\n    }\n  }\n  \n  @media screen and (max-width: 1024px) {\n    .progress {\n      width: 70%;\n    }\n  }\n  \n  @media screen and (max-width: 768px) {\n    .progress {\n      width: 90%;\n    }\n  }\n  \n  @media screen and (max-width: 425px) {\n    .progress {\n      width: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
