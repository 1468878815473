import { createSlice } from "@reduxjs/toolkit";

const headerSlice = createSlice({
  name: "header",
  initialState: {  progressValue: 0 },
  reducers: {
    setProgressValue: (state, action) => {
      state.progressValue = action.payload;
    },
  },
});

export const { setProgressValue } = headerSlice.actions;
export default headerSlice.reducer;
