export const defaultStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "#79ba39" : "#79ba39",
        height: "50px",
        boxShadow: state.isFocused ? "0 0 0 1px #79ba39" : null,
        "&:hover": {
            borderColor: "#79ba39",
        },
    }),
    option: (baseStyles, state) => {
        return {
            ...baseStyles,
            height: "50px",
            backgroundColor: state.isFocused
                ? "#a5cc7e"
                : state.isSelected
                    ? "#79ba39"
                    : null,
            "&:hover": {
                borderColor: "#a5cc7e",
                backgroundColor: "#a5cc7e",
            },
            color: state.isFocused || state.isSelected ? "white" : "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
        };
    },
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "black",
    }),

};

export const autocompleteCustomStyles = {
    option: (props, state) => ({
        ...props,
        height: "50px",
        backgroundColor: state?.isFocused
            ? "#a5cc7e"
            : state?.isSelected
                ? "#79ba39"
                : null,
        "&:hover": {
            backgroundColor: "none",
        },
        color: state?.isFocused || state?.isSelected ? "white" : "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
    }),
    singleValue: (base) => ({
        ...base,
        color: "black",
    }),
};