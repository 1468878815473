import React, { useEffect } from 'react';
import Header from './header';
import '../css/Progressbar.css';
import '../css/welcome.css';
import TransitionAnimation from './Animation/TransitionAnimation';


const NoServiceState = () => {
  const progressValue = 10;

  return (
    <>
      <Header progressValue={progressValue} />
      <TransitionAnimation>
        <div
          style={{ height: "69vh" }}
          className="container page-main1"
          >
          <div className="col-lg-4 col-md-6 col-sm-8 col-9 mx-auto text-center mx-auto">
            <h3>We’re Almost There!</h3>
            <p className="mt-4">
              We’re rapidly expanding. To be among the first to know when 
              Everlong is available in your state, 
              please{" "}
              <a
                href="https://form.jotform.com/232076932215150"
                target="_blank"
                rel="noopener noreferrer">
                click here
              </a>{" "}
              here to submit your request.
            </p>
          </div>
        </div>
      </TransitionAnimation>
    </>
  );
};

export default NoServiceState;
