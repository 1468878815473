import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers';
import Select from "react-select";
import "../css/insuranceEligibilityStyle.css"
import Alert from '@mui/material/Alert';
import axios from "axios";
import { BeatLoader } from "react-spinners";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { differenceInYears } from "date-fns";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const InsuranceEligibilityCheck = () => {
    const [focusedField, setFocusedField] = useState(null);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [dobError, setDobError] = useState("");
    const [sexError, setSexError] = useState("");
    const [insuranceError, setInsuranceError] = useState("");
    const [memberIdError, setMemberIdError] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [dob, setDob] = useState("");
    const [sex, setSex] = useState("");
    const [memberId, setMemberId] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [memberErrorCounter, setMemberErrorCounter] = useState(0);
    const [showContinueButton, setShowContinueButton] = useState(true);
    const [showBookButton, setShowBookButton] = useState(false);

    const navigate = useNavigate();

    const [insurenceProviderList, setInsurenceProviderList] = useState([])

    useEffect(() => {
        getInsurenceProviderList();
    }, [])

    useEffect(() => {
        document.body.classList.add('insurance-check-page');

        return () => document.body.classList.remove('insurance-check-page');
    }, []);

    const getInsurenceProviderList = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${REACT_APP_API_URL}master/insurence-providers`,
        };

        axios.request(config)
            .then((response) => {
                if (response.data.result && response.data.result.length > 0) {
                    const filteredList = response.data.result.filter(
                        (provider) => provider.payer_id !== "97210" && provider.payer_id !== "97211"
                    );
                    setInsurenceProviderList(filteredList);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const sexOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' }
    ];

    const [selectedInsurance, setSelectedInsurance] = useState({});

    const handleFocus = (field) => {
        setFocusedField(field);
        // setMessage("");
    };

    const handleBlur = () => {

        setFocusedField(null);

        let isValid = true;

        if (isValid && firstNameError !== "" && (!firstName || firstName.trim() === "")) {
            setFirstNameError("First name is required.");
            isValid = false;
        } else {
            setFirstNameError("");
        }

        if (isValid && lastNameError !== "" && (!lastName || lastName.trim() === "")) {
            setLastNameError("Last name is required.");
            isValid = false;
        } else {
            setLastNameError("");
        }

        if (isValid && dobError !== "" && !dob) {
            setDobError("Date of birth is required.");
            isValid = false;
        } else {
            const isValidFormat = dayjs(dob, 'MM/DD/YYYY', true).isValid();
            const age = differenceInYears(new Date(), new Date(dob));
            if (isValid && dob && !isValidFormat) {
                setDobError("Please enter a valid date in MM/DD/YYYY format.");
                isValid = false;
            } else if (age < 10) {
                setDobError("We currently do not provide services to individuals under 10 years of age.");
                isValid = false;
            } else {
                setDobError("");
            }
        }

        if (isValid && insuranceError !== "" && Object.keys(selectedInsurance).length === 0) {
            setInsuranceError("Insurance is required.");
            isValid = false;
        } else {
            setInsuranceError("");
        }

        if (isValid && memberIdError !== "" && (!memberId || memberId.trim() === "")) {
            setMemberIdError("Member ID is required.");
            isValid = false;
        } else {
            setMemberIdError("");
        }
    }

    const handleSelectChange = (selectedOption) => {
        setSelectedInsurance(selectedOption);
    };

    function navigateToBottom() {
        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth", // Optional, adds smooth scrolling animation
            });
        }, 500);
    }

    const validateForm = () => {
        let isValid = true;
        setLoading(true);

        if (isValid && (!firstName || firstName.trim() === "")) {
            setFirstNameError("First name is required.");
            isValid = false;
            setLoading(false);
        } else {
            setFirstNameError("");
        }

        if (isValid && (!lastName || lastName.trim() === "")) {
            setLastNameError("Last name is required.");
            isValid = false;
            setLoading(false);
        } else {
            setLastNameError("");
        }

        if (isValid && !dob) {
            setDobError("Date of birth is required.");
            isValid = false;
            setLoading(false);
        } else {
            const isValidFormat = dayjs(dob, 'MM/DD/YYYY', true).isValid();
            const age = differenceInYears(new Date(), new Date(dob));
            if (isValid && dob && !isValidFormat) {
                setDobError("Please enter a valid date in MM/DD/YYYY format.");
                isValid = false;
                setLoading(false);
            } else if (age < 10) {
                setDobError("We currently do not provide services to individuals under 10 years of age.");
                isValid = false;
                setLoading(false);
            } else {
                setDobError("");
            }
        }

        if (isValid && Object.keys(selectedInsurance).length === 0) {
            setInsuranceError("Insurance is required.");
            isValid = false;
            setLoading(false);
        } else {
            setInsuranceError("");
        }

        if (isValid && (!memberId || memberId.trim() === "")) {
            setMemberIdError("Member ID is required.");
            isValid = false;
            setLoading(false);
        } else {
            setMemberIdError("");
        }

        return isValid;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            if (firstNameError === "" && lastNameError === "" && dobError === "" && insuranceError === "" && memberIdError === "") {
                handleVerifyMemberId()
            } else {
                setLoading(false);
            }
        }
    };

    const handleSubmitbook = () => {
        window.open("https://signup.geteverlong.com", "_blank")
    };


    const handleVerifyMemberId = () => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${REACT_APP_API_URL}master/ins-eligibility-member-verification`,
            data: {
                firstName,
                lastName,
                dob,
                selectedInsurance,
                memberId
            }
        };

        axios.request(config)
            .then((response) => {
                setLoading(false);
                setSuccess(response.data.success);
                if (response.data.success === true) {
                    setMessage(response.data.message);
                    navigate('/insurance/eligible', { state: { success: true } });
                    setMemberErrorCounter(0);
                } else {
                    if ((response.data.isActivePlan || false) == true) {
                        setMessage(response.data.message);
                        setShowContinueButton(response.data.isShowButton || false);
                        setShowBookButton(response.data.allowToBook || false);
                    } else {
                        let eCounter = memberErrorCounter + 1;
                        setMemberErrorCounter(eCounter);
                        if (eCounter === 3) {
                            setShowContinueButton(false);
                            setMessage(
                                "Unfortunately, your insurance information still could not be verified. Please contact your insurance company to confirm your plan details."
                            );
                        } else {
                            setMessage(response.data.message);
                        }
                    }
                    navigateToBottom();
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };

    return (
        // <div style={{ backgroundColor: "#fff", minHeight: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ backgroundColor: "#fff", minHeight: "100vh", paddingTop: "3vh" }}>
            <div className="container insurance-check-main">
                <div className="row form-div col-12 col-sm-10 col-md-8 col-lg-6">
                    <div className="text-left mt-4">
                        <div className="mb-3">
                            <label htmlFor="firstName" className="mb-1" style={{ fontSize: '14px', color: 'grey' }}>First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="first_name"
                                height={54}
                                className={`form-control ins-inputbox ${focusedField === "firstName" ? "selected" : ""} ${firstNameError ? "error" : ""} hover-input`}
                                value={firstName}
                                onInput={(e) => setFirstName(e.target.value)}
                                onFocus={() => handleFocus("firstName")}
                                onBlur={handleBlur}
                                disabled={loading}  // Disable the input when loading
                            />
                            {firstNameError && <p className="text-danger mt-2" style={{ fontSize: '14px', fontWeight: "400" }}>{firstNameError}</p>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="lastName" className="mb-1" style={{ fontSize: '14px', color: 'grey' }}>Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="last_name"
                                height={54}
                                className={`form-control ins-inputbox ${focusedField === "lastName" ? "selected" : ""} ${lastNameError ? "error" : ""} hover-input`}
                                value={lastName}
                                onInput={(e) => setLastName(e.target.value)}
                                onFocus={() => handleFocus("lastName")}
                                onBlur={handleBlur}
                                disabled={loading}  // Disable the input when loading
                            />
                            {lastNameError && <p className="text-danger mt-2" style={{ fontSize: '14px', fontWeight: "400" }}>{lastNameError}</p>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="dob" className="mb-1" style={{ fontSize: '14px', color: 'grey' }}>Date of Birth</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                    id="dob"
                                    name="dob"
                                    className={`form-control ins-inputbox ${focusedField === "dob" ? "selected" : ""} ${dobError ? "error" : ""}`}
                                    // onInput={(e) => setDob(e.target.value)}
                                    onChange={(newValue) => setDob(newValue?.format('MM/DD/YYYY'))}
                                    onFocus={() => handleFocus("dob")}
                                    onBlur={handleBlur}
                                    placeholder="MM/DD/YYYY"
                                    sx={{
                                        width: "100%",
                                        "& .MuiInputBase-root": {
                                            border: dobError ? "1px solid red" : "1px solid #dee2e6",
                                            borderRadius: "0.375rem",
                                            display: "block",
                                            width: "100%",
                                            backgroundColor: "white",
                                            boxShadow: "none",
                                            outline: "none",
                                            letterSpacing: "0.01rem",
                                            "&:hover": {
                                                borderColor: "#79ba39"
                                            },
                                            "&.Mui-focused": {
                                                border: "none",
                                            },
                                            height: "54px",
                                            "@media (max-width: 1024px)": {
                                                height: "50px",
                                            },
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "left",
                                        },
                                        "& .MuiInputBase-input": {
                                            lineHeight: "2.5",
                                            padding: "0.9rem 0.60rem",
                                            color: "#000",
                                            "&:hover": {
                                                border: "none",
                                            },
                                            fontSize: "16px",
                                            // "@media (max-width: 600px)": {
                                            //     fontSize: "12px",
                                            // },
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                    disabled={loading}  // Disable the input when loading
                                />
                            </LocalizationProvider>
                            {dobError && <p className="text-danger mt-2" style={{ fontSize: '14px', fontWeight: "400" }}>{dobError}</p>}
                        </div>

                        <div className="mb-3 insuranceSelect">
                            <label htmlFor="insuranceCompany" className="mb-1" style={{ fontSize: '14px', color: 'grey' }}>Insurance Company</label>
                            <Select
                                options={insurenceProviderList}
                                value={Object.keys(selectedInsurance).length === 0 ? null : selectedInsurance}
                                getOptionLabel={(option) => option.payer_name}
                                getOptionValue={(option) => option.id}
                                placeholder="Search..."
                                onChange={handleSelectChange}
                                isSearchable
                                onFocus={() => handleFocus("selectedInsurance")}
                                onBlur={handleBlur}
                                noOptionsMessage={() => "No choices"}
                                isDisabled={loading}  // Disable the select when loading
                                className={` ins-inputbox ${insuranceError ? "error" : ""}`}
                                styles={{
                                    width: "100%",
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: insuranceError ? "red" : (state.isFocused ? "#79ba39" : "#dee2e6"),
                                        height: "54px",
                                        "@media (max-width: 1024px)": {
                                            height: "50px",
                                        },
                                        boxShadow: state.isFocused ? "0 0 0 1px #79ba39" : null,
                                        "&:hover": {
                                            borderColor: "#79ba39",
                                        },
                                        borderRadius: "0.375rem"
                                    }),
                                    option: (baseStyles, state) => {
                                        return {
                                            ...baseStyles,
                                            height: "54px",
                                            "@media (max-width: 1024px)": {
                                                height: "40px",
                                            },
                                            backgroundColor: state.isFocused
                                                ? "#a5cc7e"
                                                : state.isSelected
                                                    ? "#79ba39"
                                                    : null,
                                            "&:hover": {
                                                borderColor: "#a5cc7e",
                                                backgroundColor: "#a5cc7e",
                                            },
                                            color: state.isFocused || state.isSelected ? "white" : "black",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "left",
                                        };
                                    },
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black",
                                    })
                                }}
                            />
                            {insuranceError && <p className="text-danger mt-2" style={{ fontSize: '14px', fontWeight: "400" }}>{insuranceError} </p>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="memberId" className="mb-1" style={{ fontSize: '14px', color: 'grey' }}>Member ID</label>
                            <input
                                type="text"
                                id="memberId"
                                name="member_id"
                                height={54}
                                className={`form-control ins-inputbox ${focusedField === "memberId" ? "selected" : ""} ${memberIdError ? "error" : ""} hover-input`}
                                value={memberId}
                                onInput={(e) => setMemberId(e.target.value)}
                                onFocus={() => handleFocus("memberId")}
                                onBlur={handleBlur}
                                disabled={loading}
                            />
                            {memberIdError && <p className="text-danger mt-2" style={{ fontSize: '14px', fontWeight: "400" }}>{memberIdError} </p>}
                        </div>
                        {message && <Alert severity={success ? "success" : "error"} className="mb-3">{message}</Alert>}
                        <div className="d-flex justify-content-center">
                            {showContinueButton && !showBookButton && (<button onClick={handleSubmit} className="coverage-button" disabled={loading}>
                                {loading ? <BeatLoader color="#ffffff" style={{ width: "200px" }} size={10} /> : "Verify my coverage"}
                            </button>)}
                            {!showContinueButton && showBookButton && (<button onClick={handleSubmitbook} className="coverage-button" disabled={loading}>
                                {loading ? <BeatLoader color="#ffffff" style={{ width: "200px" }} size={10} /> : "Book my first session"}
                            </button>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsuranceEligibilityCheck;
