// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.duration-500 {
    transition: all 20.5s ease;
}

.transition-opacity {
    /* transition-property: opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1); */
}`, "",{"version":3,"sources":["webpack://./src/css/animationStyle.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI;0DACsD;AAC1D","sourcesContent":[".duration-500 {\n    transition: all 20.5s ease;\n}\n\n.transition-opacity {\n    /* transition-property: opacity;\n    transition-timing-function: cubic-bezier(.4,0,.2,1); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
