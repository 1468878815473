import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears } from "date-fns";
import { useCookies } from "react-cookie";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getUserData, updateUserData } from "../redux/action/userAction";
import moment from "moment";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import "../css/selectstates.css";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Alert } from "@mui/material";

const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 1923 }, (_, i) => {
  const year = currentYear - i;
  return { value: year, label: year.toString() };
});

const DateofBirth = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const progressValue = 30;
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [acceptError, setAcceptError] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedInsurance, setInsurance] = useState({});
  const dispatch = useDispatch();
  const [isAcceptedCondition, setIsAcceptedCondition] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [dob, setDob] = useState(null);
  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);

  const handleDateChange = (newValue) => {
    if (newValue && newValue?.$d) {
      const date = moment(newValue?.$d);
      if (!date.isValid()) {
        setSelectedDay(null);
        setSelectedMonth(null);
        setSelectedYear(null);
        return
      }
      let formattedDate = moment(newValue?.$d).format("MM-DD-YYYY");
      setDob(formattedDate);

      // Extract day, month, and year
      const day = moment(newValue?.$d).format("DD");
      const month = moment(newValue?.$d).format("MM");
      const year = moment(newValue?.$d).format("YYYY");
      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          date_of_birth: `${year}-${month}-${day}`,
        })
      );
      setError("");
      setAcceptError("");
    }
  };

  const setUserDataHandler = (uData) => {
    if (uData.date_of_birth) {
      const dateOfBirth = new Date(uData.date_of_birth);
      const date = moment(uData.date_of_birth);
      if (date.isValid()) {
        const mm = String(dateOfBirth.getUTCMonth() + 1).padStart(2, '0');
        const dd = String(dateOfBirth.getUTCDate()).padStart(2, '0');
        const YYYY = dateOfBirth.getUTCFullYear();
        setSelectedDay(dd);
        setSelectedMonth(mm);
        setSelectedYear(YYYY);
        const formattedDate = `${mm}-${dd}-${YYYY}`;
        setDob(formattedDate);
      }
    }

    if (uData?.date_of_birth_consent) {
      setIsAcceptedCondition(!!uData?.date_of_birth_consent);
    }

    if (uData.insurence && uData.insurence.insurance_provider_id) {
      setInsurance({
        value: uData.insurence.insurance_provider_id,
        label: uData.insurence.insurance_provider_name,
      });
    } else {
      setInsurance({});
    }
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/dateofbirth",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const calculateAge = () => {
    if (selectedYear && selectedMonth && selectedDay) {
      const selectedDate = new Date(
        selectedYear,
        selectedMonth - 1,
        selectedDay
      );
      const age = differenceInYears(new Date(), selectedDate);
      return age;
    }
    return -1; // Return a negative value if the date is not fully selected
  };

  const handleSelectButton = () => {
    const age = calculateAge();

    if (age >= 120) {
      return setError("Please enter your date of birth in MM/DD/YYYY format.");
      return
    }

    if (selectedYear && selectedMonth && selectedDay) {
      if (age < 10) {
        return setError(
          "We currently do not provide services to individuals under 10 years of age."
        );
      } else if (age >= 10 && age < 18 && !isAcceptedCondition) {
        return setAcceptError("Please acknowledge the attendance requirement.");
      } else if (age >= 18) {
        if (error === "") {
          navigate("/youreeligible");
        }
      }
    } else {
      return setError("Please enter your date of birth.");
    }

    if (
      selectedInsurance &&
      (selectedInsurance.label === "Private Pay" ||
        selectedInsurance.label === "HSA / FSA") &&
      age >= 10 &&
      age <= 18
    ) {
      navigate("/userdetails2");
    } else if (
      selectedInsurance &&
      (selectedInsurance.label === "Private Pay" ||
        selectedInsurance.label === "HSA / FSA") &&
      age > 17
    ) {
      navigate("/userdetails");
    } else {
      navigate("/youreeligible");
    }
  };


  const handleCheckboxChange = () => {
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        date_of_birth_consent: !isAcceptedCondition,
      })
    );
    setIsAcceptedCondition(!isAcceptedCondition);
    setError("");
    setAcceptError("");
  };

  const handleBlur = (event) => {
    // Extract the new date value from the event target
    const newValue = event.target.value;

    // Create a moment object from the new value
    const date = moment(newValue, "MM/DD/YYYY"); // Assuming the date input uses 'YYYY-MM-DD' format

    // Check if the date is valid
    if (date.isValid()) {
      const day = date.format("DD");
      const month = date.format("MM");
      const year = date.format("YYYY");

      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
    } else {
      setSelectedDay(null);
      setSelectedMonth(null);
      setSelectedYear(null);
    }
  };

  const handleFocus = (field) => {
    setFocusedField(field);
  };

  return (
    <>
      <Header progressValue={progressValue} backRoute={"/insuranceselection"} />

      <TransitionAnimation>
        <div className="container page-main1 mt-0">
          <div className="col-lg-5 col-md-8 col-sm-12  text-center mx-auto mt-5">
            <h3 className="mt-4 mb-0">What’s your date of birth?</h3>
            <p className=" mt-4 mb-2 text-center">If seeking care for a minor, please enter their date of birth.</p>
          </div>
          <div className="col-lg-5 col-md-8 col-sm-12 mt-4 col-12 mx-auto">
            <div className="justify-content-center text-center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  id="dob"
                  name="dob"
                  value={dob ? dayjs(dob, "MM/DD/YYYY") : null}
                  className={`form-control inputbox ${error ? "error" : ""}`}
                  onInput={(e) => setDob(e.target.value)}
                  onChange={(newValue) => handleDateChange(newValue)}
                  onFocus={() => handleFocus("dob")}
                  onBlur={handleBlur}
                  placeholder="MM/DD/YYYY"
                  sx={{
                    width: "70%",
                    "& .MuiInputBase-root": {
                      border: error ? "1px solid red" : "1px solid #dee2e6",
                      borderRadius: "0.375rem",
                      display: "block",
                      // width: "100%",
                      backgroundColor: "white",
                      boxShadow: "none",
                      outline: "none",
                      letterSpacing: "0.01rem",
                      "&:hover": {
                        borderColor: "#79ba39"
                      },
                      "&.Mui-focused": {
                        border: "1px solid #7ABA39",
                      },
                      height: "54px",
                    },
                    "& .MuiInputBase-input": {
                      lineHeight: "2.5",
                      padding: "0.9rem 0.60rem",
                      fontSize: "1rem",
                      color: "#000",
                      "&:hover": {
                        border: "none",
                      },
                      textAlign: "left",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-8 mx-auto" style={{ position: "relative" }}>
              {error && <p className="err-dob text-danger mx-auto mt-1 error-msg" style={{ fontSize: '14px', fontWeight: "400", position: "absolute" }}>{error}</p>}
            </div>
            <div className="mt-4">
              {(selectedYear && selectedMonth && selectedDay && calculateAge() >= 10 && calculateAge() < 18) && (
                <div className="main-check-select" style={{ marginLeft: '0px' }}>
                  <div>
                    <input
                      type="checkbox"
                      className={`check-hearby ${acceptError ? "error" : ""}`}
                      checked={isAcceptedCondition}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <Alert severity={"info"} sx={{
                    backgroundColor: "#E6D9F5", color: "#8548D3",
                    '& .MuiAlert-icon': {
                      color: "#8548D3",
                      marginRight: "10px"
                    }
                  }}>I acknowledge that a parent or guardian must attend the initial appointment of any child under 18, or the dietitian will be unable to continue the appointment, and a no-show fee will apply.
                  </Alert>
                </div>
              )}
              <div className="col-12 mx-auto" style={{ position: "relative" }}>
                {acceptError && <p className="err-insurance mx-auto text-danger mt-1 error-msg" style={{ fontSize: '14px', fontWeight: "400", position: "absolute" }}>{acceptError}</p>}
              </div>
              <TransitionAnimation aosDuration={900}>
                {(
                  <div className="mt-5">
                    <CommonButton
                      isShowArrow
                      onClick={handleSelectButton}>
                      Check Eligibility
                    </CommonButton>
                  </div>
                )}
              </TransitionAnimation>
            </div>
          </div>
        </div>
      </TransitionAnimation>
    </>
  );
};

export default DateofBirth;
