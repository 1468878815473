import React, { useEffect, useState } from "react";
import "../css/Progressbar.css";
import { useDispatch, useSelector } from "react-redux";
import { setProgressValue } from "../redux/Slice/headerSlice";

const ProgressBar = ({ value }) => {
  const progressVal = useSelector((state) => state.header?.progressValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value !== progressVal) {
      setTimeout(() => {
        dispatch(setProgressValue(value))
      }, 100);
    }
  },[value])

  
  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${progressVal}%` }}
        aria-valuenow={progressVal}
        aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
  );
};

export default ProgressBar;
