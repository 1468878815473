import React, { Fragment, useEffect } from "react";
import AOS from "aos"; // Import AOS functionality to initialize
import "aos/dist/aos.css"; // Import AOS styles
import "../../css/animationStyle.css";

const TransitionAnimation = ({ children, aosDuration = 1000 }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Fragment>
      <div
        data-aos="fade" // Use a simple fade effect instead of zoom-in
        data-aos-easing="ease" // Apply easing function
        data-aos-delay="600"
        data-aos-duration={aosDuration} // Set duration for transition
      >
        {children}
      </div>
    </Fragment>
  );
};

export default TransitionAnimation;
