import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import "../css/Userdetails.css";
import "../css/DietitianScreen.css";
import { useDispatch, useSelector } from "react-redux";

import CountdownTimer from "./CountdownTimer";

import Select from "react-select";
import { defaultStyles } from "../utils/helpers/SelectCustoms";
import { useCookies } from "react-cookie";
import { getUserData, updateUserData } from "../redux/action/userAction";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { searchDietitians } from "../redux/action/insurenceProviderAction";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import { Autocomplete, TextField } from "@mui/material";
import { set } from "date-fns";

const dropdownOptions = [
  { label: "College or University", value: "College or University" },
  { label: "Doctor", value: "Doctor" },
  { label: "Employer", value: "Employer" },
  { label: "Everlong Employee", value: "Everlong Employee" },
  { label: "Friend or Family", value: "Friend or Family" },
  { label: "Google", value: "Google" },
  { label: "Gym or Fitness Studio", value: "Gym or Fitness Studio" },
  { label: "Social Media", value: "Social Media" },
  { label: "Zocdoc", value: "Zocdoc" },
  { label: "Other", value: "Other" },

  // { label: "HealthProfs", value: "HealthProfs" },
  // { label: "Insurance Directory", value: "Insurance" },
  // { label: "Past or Current Client", value: "Past or Current Client" },
];

const Referred = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const progressValue = 70;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [referred, setReferred] = useState({});
  const [additionalInfo, setAdditionalInfo] = useState("");

  const userData = useSelector((state) => state.user[USER_DATA]);
  const dietitianList = useSelector(
    (state) => state.insurence?.DIETITIAN_DATA_LIST || []
  );
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);

  const [error, setError] = useState(false);
  const [additionalInfoError, setAdditionalInfoError] = useState(false);
  const [focusedField, setFocusedField] = useState(null);

  const handleDietitianSearch = (selectedOption) => {
    setAdditionalInfo(selectedOption || "");

    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        referred_info: selectedOption || null,
      })
    );
    setAdditionalInfoError(false);
  };

  const handleInputChange = (newInputValue) => {
    setAdditionalInfo(newInputValue);
    // dispatch(
    //   updateUserData({
    //     evuid: cookies["evuid"],
    //     referred_info: newInputValue || null,
    //   })
    // );
    if (newInputValue && newInputValue.length >= 3) {
      dispatch(searchDietitians(newInputValue));
    }
    setAdditionalInfoError(false);
  };

  const dietitianOptions = dietitianList.map((dietitian) => ({
    label: dietitian.name,
    value: dietitian.name,
  }));

  const handleOptionChange = (selectedOption) => {
    setReferred(selectedOption);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        referred: selectedOption,
      })
    );
    setError(false);
  };

  const handleAdditionalInfoChange = (e) => {
    setAdditionalInfo(e.target.value);
    setError(false);
    setAdditionalInfoError(false);
  };

  const handleAdditionalInfoSave = (e) => {
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        referred_info: e.target.value,
      })
    );
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/referred",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  const setUserDataHandler = (uData) => {
    if (uData.referred && uData.referred.value) {
      setReferred(uData.referred);
    } else {
      setReferred({});
    }

    if (uData.referred_info && uData.referred_info) {
      setAdditionalInfo(uData.referred_info);
    } else {
      setAdditionalInfo("");
    }
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const handleSelectButton = () => {
    if (!referred || (referred && !referred.value)) {
      setError("Please select how you heard about us.");
      return;
    }

    if (
      referred &&
      referred.value &&
      (referred?.value === "Employer" ||
        referred?.value === "Gym or Fitness Studio" ||
        referred?.value === "College or University" ||
        // referred?.value === "Past or Current Client" ||
        // referred?.value === "Everlong Employee" ||
        referred?.value === "Other") &&
      (!additionalInfo || (additionalInfo && additionalInfo.trim() === ""))
    ) {
      setAdditionalInfoError("Please share who referred you.");
      return;
    }

    // if (
    //   referred &&
    //   referred.value &&
    //   referred?.value === "Everlong Employee" &&
    //   (!additionalInfo || (additionalInfo && additionalInfo === 0))
    // ) {
    //   setAdditionalInfoError("Please share who referred you.");
    //   return;
    // }

    const matchDietitian = dietitianOptions.some(
      (dietitian) => dietitian.value === additionalInfo
    );

    if (referred &&
      referred.value &&
      referred?.value === "Everlong Employee" && !matchDietitian) {
      setAdditionalInfoError("Please select an employee from the list.");
      return;
    }

    if (
      referred &&
      referred.value &&
      referred.value !== "Doctor" &&
      // referred.value !== "Past or Current Client" &&
      referred.value !== "Everlong Employee" &&
      referred.value !== "Other"
    ) {
      setAdditionalInfo("");
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          referred_info: "",
        })
      );
    }

    setError(false);
    setAdditionalInfoError(false);
    navigate("/insuranceinformation");
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const customComponents = {
    DropdownIndicator: () => null,  // Remove the arrow
    IndicatorSeparator: () => null, // Remove the separator line
  };

  return (
    <>
      <Header progressValue={progressValue} styles={{ position: "relative" }} />
      <div className="timer-top-right">
        <CountdownTimer />
      </div>
      <TransitionAnimation>
        <div className="container page-main1 mt-0">

          <div className="col-lg-8 col-md-10 col-sm-12 text-left mx-auto mt-5">
            <h3 className="mt-4 text-center">How did you hear about us?</h3>
            <div className="row mt-4">
              <div
                className={`col-md-8 mx-auto mb-3 ${error && !referred ? "error" : ""
                  }`}>
                <div
                  className={`col-md-8 mx-auto ${error && !referred ? "error" : ""
                    }`}>
                  <Select
                    id="selectOption"
                    // className={`${referred ? 'selected' : ''}`}
                    value={referred}
                    className={` ${error ? "error-select" : ""}`}
                    onChange={handleOptionChange}
                    options={dropdownOptions}
                    placeholder={""}
                    noOptionsMessage={() => "No choices"}
                    isSearchable
                    styles={defaultStyles}
                  />
                  <div style={{ position: 'relative' }}>
                    {error && (
                      <p
                        className="mx-auto text-danger mt-1 error-msg"
                        style={{
                          fontSize: '14px',
                          fontWeight: '400',
                          position: 'absolute',
                          top: '0',
                        }}
                      >
                        {error}
                      </p>
                    )}
                  </div>

                  {referred &&
                    (referred?.value === "Employer" ||
                      referred?.value === "Gym or Fitness Studio" ||
                      referred?.value === "College or University" ||
                      referred?.value === "Other") && (
                      <>
                        <input
                          type="text"
                          id="additionalInfo"
                          className={`form-control mt-4 inputbox ${additionalInfoError && !additionalInfo ? "error-select" : ""
                            } ${focusedField === "additionalInfo" ? "selected" : ""
                            }`}
                          onChange={handleAdditionalInfoChange}
                          onBlur={handleAdditionalInfoSave}
                          onFocus={() => handleFocus("additionalInfo")}
                          value={additionalInfo}
                          placeholder="Referred by"
                        />
                        <div style={{ height: '24px', position: 'relative' }}>
                          {additionalInfoError && (
                            <p
                              className="mx-auto text-danger mt-1 error-msg"
                              style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                position: 'absolute',
                                top: '0',
                              }}
                            >
                              {additionalInfoError}
                            </p>
                          )}
                        </div>
                      </>
                    )}

                  {referred?.value === "Everlong Employee" && (
                    <>
                      <Autocomplete
                        freeSolo
                        popupIcon={null}
                        className="inputbox mt-4"
                        id="creatable-autocomplete"
                        options={dietitianOptions.map((option) => option.value)}
                        inputValue={additionalInfo || ""}
                        onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
                        onChange={(event, newValue) => handleDietitianSearch(newValue)}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Start typing employee's name"
                            sx={{
                              height: { xs: "43px", sm: "48px" },
                              border: additionalInfoError ? '1px solid red' : '1px solid #79ba39',
                              borderRadius: '4px',
                              '& fieldset': {
                                border: 'none',
                              },
                              backgroundColor: 'white',
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                              '& input': {
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '-5px',
                              },
                            }}
                          />
                        )}
                        renderOption={(props, option, { selected, focused }) => (
                          <li
                            {...props}
                            style={{
                              height: "50px",
                              margin: "0",
                              backgroundColor: focused
                                ? "#a5cc7e"
                                : selected
                                  ? "#79ba39"
                                  : "white",
                              color: focused || selected ? "white" : "black",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                              paddingLeft: "8px",
                              transition: "background-color 0.2s",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#a5cc7e";
                              e.currentTarget.style.color = "white";
                            }}
                            onMouseLeave={(e) => {
                              if (!focused && !selected) {
                                e.currentTarget.style.backgroundColor = "white";
                                e.currentTarget.style.color = "black";
                              }
                            }}
                          >
                            {option}
                          </li>
                        )}
                      />
                      <div style={{ height: '24px', position: 'relative' }}>
                        {additionalInfoError && (
                          <p
                            className="mx-auto text-danger mt-1 error-msg"
                            style={{
                              fontSize: '14px',
                              fontWeight: '400',
                              position: 'absolute',
                              top: '0',
                            }}
                          >
                            {additionalInfoError}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-10 col-sm-12 mx-auto">
            {/* {error && (
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Alert
                  severity="error"
                  style={{ display: "inline-flex" }}
                >
                  {error}
                </Alert>
              </div>
            )} */}
            <div className="mt-3"></div>
            <CommonButton
              isShowArrow
              className={` ${error ? "selected" : ""}`}
              onClick={handleSelectButton}>
              Continue
            </CommonButton>
          </div>
        </div>
      </TransitionAnimation>
    </>
  );
};

export default Referred;
