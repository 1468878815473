import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import heic2any from 'heic2any';
import Spinner from '../Spinner';

async function convertHeicToJpgOrPng(heicFile, setIsLoading) {
  try {
    // Convert HEIC to JPG or PNG
    setIsLoading(true)
    const result = await heic2any({
      blob: heicFile,
      toType: 'image/jpeg', // Always convert to JPEG
    });
    // 'result' is a Blob containing the converted image data
    return new File([result], 'converted.jpg', { type: 'image/jpeg' });
  } catch (error) {
    console.error('Error converting HEIC to JPG/PNG:', error);
  } finally {
    setIsLoading(false)
  }
}

const InsurenceUpload = (props) => {
  const [fileData, setFileData] = useState(null);
  const [loading, setIsLoading] = useState(false)

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    let fileType = file.type?.split('/')[0];
    if (fileType && (props?.accept).includes(fileType) || props.accept === '*') {
      if (file.size > 10000000) {
        props.setURL('');
        return props?.setImageError({ index: 0, error: "The file size of your insurance cards is too large. Please compress them or upload smaller files." });
      }
      if (file.type?.includes('heic') || file.type?.includes('heif')) {
        const convertedImageFile = await convertHeicToJpgOrPng(file, setIsLoading);
        // const imageUrl = URL.createObjectURL(convertedImageFile);
        props.setURL(convertedImageFile); // Set the converted File object
        setFileData(convertedImageFile); // Store image data
      } else {
        props?.setImageError({ index: props?.index, error: '' });
        props.setURL(file); // Set the original file object
        setFileData(file); // Store image data
      }
    } else {
      // Reset the file input
      if (props.fileInputRef.current) {
        props.fileInputRef.current.value = ''; // This clears the selected file
      }
      props.setURL('');
      props?.setImageError({ index: props?.index, error: '*Please ensure that you are uploading a valid image file in one of the following formats: PNG, JPG, JPEG, and SVG.' });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={`image-upload-box ${props?.imageError.index === 3 ? 'error' : ''}  ${props?.imageError?.error && props?.index === props?.imageError.index ? "error" : ''}`} {...getRootProps()}>
      {
        loading ? (
          <div className="spinner-div-min-height">
            <Spinner text="Please wait" />
          </div>

        ) :

          <div>
            <p className="h5">{props.title}</p>
            {props.fileUrl ? (
              <div className="text-center mt-2 mb-2">
                {!fileData ? (
                  <img
                    src={URL.createObjectURL(props.fileUrl)}
                    alt={`Uploaded ${props.title} `}
                    className='uploadImg'
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(fileData) || ""}
                    alt={`Uploaded ${props.title} `}
                    className='uploadImg'
                  />
                )}
              </div>
            ) : ''}
            {/* Always show the input type file block */}
            <div className={`dropzone ${isDragActive ? "drag-active" : ""}`}>
              <input ref={props.fileInputRef} {...getInputProps()} />
              <div className="upload-content">
                {(!fileData && !props.fileUrl) && <FontAwesomeIcon icon={faUpload} size="xl" color="#888" className='pt-1 pb-2' />}
                <p className='mb-0' style={{ fontSize: "14px" }}>{isDragActive ? "Drop the file here ..." : "Drag a photo of your insurance card here or click to select a file"}</p>
              </div>
            </div>
          </div>}
    </div>
  );
}

export default InsurenceUpload;
