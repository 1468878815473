import React, { Fragment } from "react";
import "./Button.css"

const CommonButton = ({ id, onClick, className,children,isShowArrow=false, ...rest }) => {
  return (
    <Fragment>
      <button
        id={id}
        type="button"
        className={`common-btn ${className}`}
        onClick={onClick}
        {...rest}>
        {children}
      </button>
    </Fragment>
  );
};

export default CommonButton;
