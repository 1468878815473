import React, { useEffect, useMemo, useState } from "react";
import "./DititianBlock.css";
import "../../css/AppointmentBooking.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faFilePrescription, faBiohazard, faHandHoldingDroplet, faHeartCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Rating } from "@mui/material";
import { styled } from '@mui/material/styles';
import { AutoAwesome, AutoFixHigh, Padding, StarBorderRounded, StarRounded, TipsAndUpdates } from "@mui/icons-material";
import { USER_DATA } from "../../utils/constants/user";
import { useSelector } from "react-redux";
import { sub } from "date-fns";

// Slick settings for auto-scrolling
const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const DititianBlock = ({ generateSlotButton, slotList, excellentMatch, subOptionsList }) => {
  const [availableSlot, setAvailableSlot] = useState([]);
  const [hideMore, setHideMore] = useState(true);
  const [expandedStates, setExpandedStates] = useState(
    Array(slotList?.reviews.length).fill(false)
  );
  const userData = useSelector((state) => state.user[USER_DATA]);
  const [speciality, setSpeciality] = useState([]);

  useEffect(() => {
    setSpeciality(prevSpeciality => {
      const updatedSpeciality = new Set(prevSpeciality);

      if (userData?.specialty?.subOptions?.label) {
        updatedSpeciality.add(userData.specialty.subOptions.label);
        updatedSpeciality.add(userData.specialty.label);
      } else {
        updatedSpeciality.add(userData.specialty.label);
      }
      if (userData.specialty.label === 'Other') {
        updatedSpeciality.clear();
        updatedSpeciality.add('General Health');
        updatedSpeciality.add('Weight Management');
        updatedSpeciality.add('Emotional Eating');
      }
      if (subOptionsList.length > 0) {
        subOptionsList.forEach(option => {
          updatedSpeciality.add(option.label);
        });
      }

      return Array.from(updatedSpeciality);
    });
  }, [userData, subOptionsList]);

  const toggleExpand = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  useMemo(() => {
    let tempSlot = slotList?.slots || [];
    setAvailableSlot(tempSlot);
  }, [slotList]);

  const CustomRating = styled(Rating)({
    '& .MuiRating-icon': {
      fontSize: '24px',
      color: '#FFC107'
    }
  });
  return (
    <div className="dititian-blk">
      <div className="mb-3 d-flex flex-column  blk-border">
        {/* Excellent match  */}
        {/* {excellentMatch && (
          <div className="excellent-div mb-3">
            <span>Excellent fit</span>
          </div>
        )} */}
        <div className="d-flex flex-row align-items-center gap-3 mb-4">
          <div className="img-section">
            {slotList?.dietitian?.dietitian_image && (
              <img
                className="dietitian-img"
                src={slotList?.dietitian?.dietitian_image}
                alt="dietitian-img"
              />
            )}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between" style={{ width: '100%' }}>
            <div className="name-section d-flex flex-column">
              <h4 className="mb-0">{slotList?.dietitian?.name || ""}</h4>
              <span style={{ fontSize: '80%' }}>{slotList?.dietitian?.designation || "-"}</span>
            </div>
            <div>
              {excellentMatch && (
                <div className="excellent-div">
                  <span>Excellent fit</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* need to fix for more than 2 lines */}
        {/* Specialities section  */}
        {/* {subOptionsList.length > 0 && ( */}
        <div className="mb-4">
          <p className="mb-2 title-grey">SPECIALTIES</p>
          <div className="speciality-grid slots">
            {speciality && speciality.map((option) => (
              <div className="option-item">
                <FontAwesomeIcon icon={faHeartCircleCheck} style={{ fontSize: "0.875rem", marginRight: "5px" }} />
                {option}
              </div>
            ))}
          </div>
        </div>
        {/* )} */}

        {/* availability section  */}
        {availableSlot.length > 0 && (
          <div className="mb-1">
            <p className="mb-2 title-grey">AVAILABILITY</p>
            <div className="availability-grid slots">
              {availableSlot.length > 0 &&
                generateSlotButton(
                  hideMore ? availableSlot.slice(0, 5) : availableSlot,
                  "morning",
                  slotList
                )}
            </div>
            {availableSlot.length > 5 && (
              <div className="row">
                <div
                  className="col-12 cursor-pointer text-center grey-text"
                  onClick={() => setHideMore(!hideMore)}
                >
                  {hideMore ? "See more" : "See less"}
                </div>
              </div>
            )}
          </div>
        )}

        {/* for  slider section  */}
        <div className="mb-4">
          <p className="mb-1 title-grey">REVIEWS</p>
          {slotList?.reviews?.length > 0 && (
            <div className="auto-scroll-slider">
              <Slider {...sliderSettings}>
                {slotList?.reviews.map((item, index) => (
                  <div key={item.id}>
                    <div className="d-flex align-items-center justify-left mb-2">
                      <p className="author mb-0" style={{ fontSize: "14px", marginRight: "8px", fontStyle: "italic" }}>
                        {item.author_name || ""}
                      </p>
                      <span style={{ marginRight: "8px" }}>—</span>
                      <CustomRating
                        size="small"
                        value={item.rating}
                        precision={0.1}
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={<StarBorderRounded fontSize="inherit" />}
                        readOnly
                      />
                    </div>
                    <p style={{ fontSize: "13.5px", paddingBottom: "0px", marginBottom: "0px" }}>
                      {expandedStates[index]
                        ? item.review_text
                        : item.review_text.length > 175
                          ? item.review_text.slice(0, 175) + "..."
                          : item.review_text}
                      {item.review_text.length > 175 && (
                        <span
                          className="cursor-pointer ms-2 text-theme-green"
                          onClick={() => toggleExpand(index)}
                        >
                          {expandedStates[index] ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                          )}
                        </span>
                      )}
                    </p>
                    {/* <div className="d-flex justify-content-between align-items-center">
                      <p className="author mb-0" style={{ fontSize: "14px" }}>{item.author_name || ""}</p>
                      <CustomRating
                        size="small"
                        value={item.rating}
                        precision={0.1}
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={<StarBorderRounded fontSize="inherit" />}
                        readOnly
                      />
                    </div> */}
                    {/* <div className="d-flex align-items-center justify-content-center" style={{ margin: "10px 0", padding: "10px", border: "1px solid #9ac96c", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
                      <p className="author mb-0" style={{ fontSize: "14px", fontWeight: "600", color: "#333", marginRight: "8px" }}>
                        {item.author_name || ""}
                      </p>
                      <span style={{ marginRight: "8px", color: "#888" }}>—</span>
                      <CustomRating
                        size="small"
                        value={item.rating}
                        precision={0.1}
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={<StarBorderRounded fontSize="inherit" />}
                        readOnly
                        title={`Rating: ${item.rating}`}
                      />
                    </div> */}
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DititianBlock;
