// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-upload-button {
  background-color: transparent; /* Transparent background */
  color: #9ac96c; /* Text color */
  border: 1px solid #9ac96c; /* Border color */
  border-radius: 10px; /* Border radius */
  padding: 16px 60px; /* Padding */
  transition: all 0.2s ease-in-out; /* Transition */
}

/* Custom button styles on hover */
.custom-upload-button:hover {
  background-color: #9ac96c; /* Background color on hover */
  color: #fff; /* Text color on hover */
}

.file-name {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.remove-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #333333d4;
}

.send-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  background-color: #9ac96c;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
}

.send-button:hover {
  background-color: #79ba39;
}`, "",{"version":3,"sources":["webpack://./src/css/AppointmentConfirmation.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B,EAAE,2BAA2B;EAC1D,cAAc,EAAE,eAAe;EAC/B,yBAAyB,EAAE,iBAAiB;EAC5C,mBAAmB,EAAE,kBAAkB;EACvC,kBAAkB,EAAE,YAAY;EAChC,gCAAgC,EAAE,eAAe;AACnD;;AAEA,kCAAkC;AAClC;EACE,yBAAyB,EAAE,8BAA8B;EACzD,WAAW,EAAE,wBAAwB;AACvC;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,YAAY;EACZ,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".custom-upload-button {\n  background-color: transparent; /* Transparent background */\n  color: #9ac96c; /* Text color */\n  border: 1px solid #9ac96c; /* Border color */\n  border-radius: 10px; /* Border radius */\n  padding: 16px 60px; /* Padding */\n  transition: all 0.2s ease-in-out; /* Transition */\n}\n\n/* Custom button styles on hover */\n.custom-upload-button:hover {\n  background-color: #9ac96c; /* Background color on hover */\n  color: #fff; /* Text color on hover */\n}\n\n.file-name {\n  margin-top: 10px;\n  display: flex;\n  justify-content: center;\n}\n\n.remove-icon {\n  margin-left: 10px;\n  cursor: pointer;\n  font-size: 18px;\n  color: #333333d4;\n}\n\n.send-button {\n  position: absolute;\n  bottom: 5px;\n  right: 5px;\n  border: none;\n  cursor: pointer;\n  font-size: 12px;\n  background-color: #9ac96c;\n  color: white;\n  border-radius: 5px;\n  padding: 5px 10px;\n}\n\n.send-button:hover {\n  background-color: #79ba39;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
