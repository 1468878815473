import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Select from "react-select";

import Header from "./header";
import "../css/selectstates.css";
import { useDispatch, useSelector } from "react-redux";
import { defaultStyles } from "../utils/helpers/SelectCustoms";
import { getUserData, updateUserData } from "../redux/action/userAction";
import { getStateList } from "../redux/action/stateAction";
import { STATE_LIST } from "../utils/constants/state";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import { Alert } from "@mui/material";

const specialStates = []; //["California", "Alabama", "Tennessee"];
const SelectStates = () => {
  const progressValue = 10;
  const [cookies, setCookie] = useCookies(["evuid"]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const stateList = useSelector((state) =>
    state.state[STATE_LIST]?.map((stData) => {
      return {
        id: stData.id,
        value: stData.code,
        label: stData.name,
        is_support: stData.is_support,
      };
    })?.sort((a, b) => a.label.localeCompare(b.label))
  );

  const [selectedState, setSelectStates] = useState({});
  const [error, setError] = useState(false);
  const [isAcceptedCondition, setIsAcceptedCondition] = useState(false);

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/selectstates",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
      if (!stateList || (Array.isArray(stateList) && stateList.length === 0)) {
        dispatch(getStateList());
      }
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const handleSelectChange = (selectedOption) => {
    setSelectStates(selectedOption);
    setIsAcceptedCondition(false);
    setError(false);
  };

  const handleSelectButton = () => {
    if (selectedState && selectedState.value) {
      setError(false);
      if (
        specialStates?.includes(selectedState?.label) &&
        !isAcceptedCondition
      ) {
        setError("Please acknowledge the referral requirement.");
      } else if (selectedState && selectedState.is_support === false) {
        navigate("/noservicestate");
        dispatch(
          updateUserData({
            evuid: cookies["evuid"],
            state: selectedState,
            check_state_consent: isAcceptedCondition,
          })
        );
      } else {
        navigate("/insuranceselection");
        dispatch(
          updateUserData({
            evuid: cookies["evuid"],
            state: selectedState,
            check_state_consent: isAcceptedCondition,
          })
        );
      }
    } else {
      setError("Please select your state.");
    }
  };

  const setUserDataHandler = (uData) => {
    if (uData?.check_state_consent) {
      setIsAcceptedCondition(!!uData?.check_state_consent);
    }
    if (uData.state) {
      setSelectStates(uData.state);
    } else {
      setSelectStates({});
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAcceptedCondition(e.target.checked);
    setError("");
  };

  return (
    <>
      <Header progressValue={progressValue} backRoute={"/"} />

      <TransitionAnimation>
        <div className="container page-main1 select-state-height">
          <div className="row">
            <div className="col-lg-12 col-md-8 col-sm-12 text-left mx-auto mt-5">
              <h3 className="text-center">Where do you currently reside?</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12 col-md-9 col-sm-8 col-9 text-left mx-auto">
              <p className="text-center">
                This ensures our dietitians are licensed to practice in your state.
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-12 mt-3 col-10 mx-auto">
            <Select
              options={stateList}
              placeholder={(selectedState && selectedState.label) || ""}
              value={selectedState}
              onChange={handleSelectChange}
              isSearchable
              noOptionsMessage={() => "No state is available"}
              styles={defaultStyles}
              className={`select-state ${error ? "error-select" : ""}`}
            />
            <div className=" select-state mx-auto" style={{ position: 'relative' }}>
              {error && (
                <p className=" text-danger mt-1 error-msg" style={{
                  fontSize: '14px', fontWeight: "400", position: 'absolute',
                  top: '0',
                }}>
                  {error}
                </p>
              )}
            </div>
            {selectedState?.label && specialStates?.includes(selectedState?.label) && (
              <div className="mt-5 main-check-select">
                <div>
                  <input
                    type="checkbox"
                    className={`check-hearby ${error ? "error" : ""}`}
                    checked={isAcceptedCondition}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <Alert severity={"info"} sx={{
                  backgroundColor: "#E6D9F5", color: "#8548D3",
                  '& .MuiAlert-icon': {
                    color: "#8548D3",
                    marginRight: "10px"
                  }
                }}>
                  In your state, you must have a referral to see a registered
                  dietitian. You acknowledge that you will upload a referral
                  to your client portal or reschedule your appointment at
                  least 24 hours in advance and that failure to do so will
                  result in a no-show/late-cancel fee. If needed, download our{" "}
                  <a
                    className="link-with-a"
                    href="/src/PatientReferralForm.pdf"
                    download>
                    Patient Referral Form
                  </a>
                  .
                </Alert>
              </div>
            )}

            <CommonButton
              isShowArrow
              className="mt-5"
              onClick={handleSelectButton}>
              Continue
            </CommonButton>
          </div>
        </div>


      </TransitionAnimation>
    </>
  );
};

export default SelectStates;
